import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Input, message, Modal } from 'antd';
import { SaveOutlined, UserAddOutlined } from '@ant-design/icons';
import { AiOutlineUser } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';

import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';
import { Firebase } from '../config/firebase';
import {
      GlobalDispatchContext,
      GlobalStateContext,
} from '../context/GlobalContextProvider';

import '../styles/project-settings.scss';

// markup
const ProjectSettings = () => {
      const [projectForm] = Form.useForm();
      const [teamForm] = Form.useForm();
      const dispatch = useContext(GlobalDispatchContext);
      const state = useContext(GlobalStateContext);
      const { selectedProjectId } = state;

      const [isNewProjectDrawerOpen, setIsNewProjectDrawerOpen] =
            useState(false);
      const [isAddingUser, setIsAddingUser] = useState(false);
      const [isProjectOwner, setIsProjectOwner] = useState(false);
      const [isUpdatingProject, setIsUpdatingProject] = useState(false);
      const [userSettings, setUserSettings] = useState(null);
      const [loading, setLoading] = useState(false);
      const [loadingReset, setLoadingReset] = useState(false);
      const [accounts, setAccounts] = useState([]);
      const [isModalVisible, setIsModalVisible] = useState(false);

      useEffect(() => {
            setLoading(true);
            setIsProjectOwner(false);

            if (selectedProjectId) {
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              setUserSettings(user);
                              getTeamMembers(user.uid, user);
                        }
                  });
            }
      }, [selectedProjectId]);

      /**
       * Get user accounts associated with project
       *
       * @param {*} uid
       */
      const getTeamMembers = (uid, user) => {
            axios.get(
                  `${process.env.GATSBY_BASE_URL}/projects/team/${uid}/${state.selectedProjectId}`
            )
                  .then(function (response) {
                        setLoading(false);
                        setAccounts(response.data);

                        // Find owner
                        let isOwner = response.data.find((member) => {
                              return (
                                    'owner' === member.role &&
                                    member.uid === user.uid
                              );
                        });

                        if (isOwner) {
                              setIsProjectOwner(true);
                        }
                  })
                  .catch(function (error) {
                        console.log(error);
                  });
      };

      /**
       * Add user to project
       *
       * @param {*} data
       */
      const handleAddTeamMember = (data) => {
            const { uid } = userSettings;
            if (uid && selectedProjectId) {
                  setIsAddingUser(true);

                  axios.post(
                        `${process.env.GATSBY_BASE_URL}/projects/team/${uid}/${selectedProjectId}`,
                        {
                              cloud_name: data.cloud_name.toLowerCase(),
                        }
                  )
                        .then((response) => {
                              if (response.data) {
                                    message.success('User added to project.');
                                    getTeamMembers(uid, userSettings);

                                    // reset the form
                                    teamForm.resetFields();

                                    // close the drawer
                                    setIsNewProjectDrawerOpen(false);
                              }
                        })
                        .catch((err) => {
                              if (
                                    err.response &&
                                    err.response.data &&
                                    err.response.data.message
                              ) {
                                    teamForm.setFields([
                                          {
                                                name: 'cloud_name',
                                                value: data.cloud_name,
                                                touched: false,
                                                errors: [
                                                      err.response.data.message,
                                                ],
                                          },
                                    ]);
                              }
                        })
                        .finally(() => {
                              setIsAddingUser(false);
                        });
            }
      };

      const handleUpdateProject = (data) => {
            const { uid } = userSettings;
            if (uid && selectedProjectId) {
                  setIsUpdatingProject(true);

                  axios.post(
                        `${process.env.GATSBY_BASE_URL}/projects/${uid}/${selectedProjectId}`,
                        {
                              name: data.name,
                        }
                  )
                        .then((response) => {
                              if (response.data) {
                                    axios.get(
                                          `${process.env.GATSBY_BASE_URL}/projects/${uid}`
                                    ).then((response) => {
                                          dispatch({
                                                type: 'SET_PROJECTS',
                                                projects: response.data,
                                          });
                                    });

                                    message.success(
                                          'Project updated successfully.'
                                    );
                              }
                        })
                        .catch((err) => {
                              if (
                                    err.response &&
                                    err.response.data &&
                                    err.response.data.message
                              ) {
                                    projectForm.setFields([
                                          {
                                                name: 'name',
                                                value: data.name,
                                                touched: false,
                                                errors: [
                                                      err.response.data.message,
                                                ],
                                          },
                                    ]);
                              }
                        })
                        .finally(() => {
                              setIsUpdatingProject(false);
                        });
            }
      };

      const removeUserFromProject = (item) => {
            const { uid, email } = item;
            const r = window.confirm(
                  `You want to remove ${email} from project?`
            );

            if (r === true) {
                  axios.delete(
                        `${process.env.GATSBY_BASE_URL}/projects/team/${userSettings.uid}/${selectedProjectId}/${uid}`
                  )
                        .then((response) => {
                              if (response.data) {
                                    getTeamMembers(
                                          userSettings.uid,
                                          userSettings
                                    );
                                    message.success(
                                          'User removed from project.'
                                    );
                              }
                        })
                        .catch((err) => {
                              console.log(err.message);
                        });
            }
      };

      return (
            <>
                  <SEO title="Settings — NoCodeAPI" />
                  <AppContainer activeNav="project">
                        <div className="page-content-header">
                              <h2>Settings</h2>
                              <p>Your current project settings</p>
                        </div>
                        <div className="project-edit" style={{ maxWidth: 400 }}>
                              <p>
                                    <b>
                                          <u>Project name</u>
                                    </b>
                              </p>
                              <Form
                                    form={projectForm}
                                    layout="vertical"
                                    onFinish={handleUpdateProject}
                              >
                                    <Form.Item
                                          label="Give a name"
                                          name="name"
                                          rules={[
                                                {
                                                      required: true,
                                                      message: 'Project name is required!',
                                                },
                                          ]}
                                          extra={
                                                <div
                                                      style={{
                                                            fontWeight: 300,
                                                            fontSize: 14,
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                            __html: 'Give a new name to this project.',
                                                      }}
                                                />
                                          }
                                          initialValue={
                                                state.projects?.find(
                                                      (p) =>
                                                            p._id ==
                                                            state.selectedProjectId
                                                )?.name
                                          }
                                    >
                                          <Input minLength="3" />
                                    </Form.Item>

                                    <Form.Item>
                                          <Button
                                                type="primary"
                                                loading={loadingReset}
                                                htmlType="submit"
                                                className="make-btn"
                                                style={{
                                                      fontSize: 13,
                                                      fontWeight: 400,
                                                }}
                                                icon={<SaveOutlined />}
                                          >
                                                {isUpdatingProject
                                                      ? 'Saving Changes'
                                                      : 'Save Changes'}
                                          </Button>
                                    </Form.Item>
                              </Form>
                              <p>
                                    <b>
                                          <u>Team members</u>
                                    </b>
                              </p>
                              {accounts.map((account, key) => (
                                    <div className="team-account" key={key}>
                                          <AiOutlineUser />
                                          <div className="team-meta">
                                                <b>{account.email}</b>
                                                <p>{account.role}</p>
                                          </div>
                                          {'owner' !== account.role &&
                                                isProjectOwner && (
                                                      <div
                                                            onClick={() =>
                                                                  removeUserFromProject(
                                                                        account
                                                                  )
                                                            }
                                                      >
                                                            <RiDeleteBin6Line />
                                                      </div>
                                                )}
                                    </div>
                              ))}
                              <p onClick={() => setIsModalVisible(true)}>
                                    <b>
                                          <u>
                                                + Add new team member into this
                                                project
                                          </u>
                                    </b>
                              </p>
                              <Modal
                                    title="Add new team member"
                                    visible={isModalVisible}
                                    onCancel={() => setIsModalVisible(false)}
                                    footer={null}
                              >
                                    <Form
                                          layout="vertical"
                                          form={teamForm}
                                          onFinish={handleAddTeamMember}
                                    >
                                          <Form.Item
                                                label="Username"
                                                name="cloud_name"
                                                rules={[
                                                      {
                                                            required: true,
                                                            message: 'Please input username!',
                                                      },
                                                      ({ getFieldValue }) => ({
                                                            validator(
                                                                  _,
                                                                  value
                                                            ) {
                                                                  if (
                                                                        /^[A-Za-z0-9_]+$/.test(
                                                                              value
                                                                        )
                                                                  ) {
                                                                        return Promise.resolve();
                                                                  }
                                                                  return Promise.reject(
                                                                        new Error(
                                                                              'special keywords not allowed'
                                                                        )
                                                                  );
                                                            },
                                                      }),
                                                ]}
                                                extra={
                                                      <div
                                                            style={{
                                                                  fontWeight: 300,
                                                                  fontSize: 14,
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                  __html: 'Enter username of the user to add',
                                                            }}
                                                      />
                                                }
                                          >
                                                <Input
                                                      pattern="[A-Za-z0-9_]+"
                                                      minLength="3"
                                                />
                                          </Form.Item>
                                          <br />
                                          <Form.Item>
                                                <Button
                                                      type="primary"
                                                      htmlType="submit"
                                                      loading={isAddingUser}
                                                      className="make-btn"
                                                      style={{
                                                            fontSize: 13,
                                                            fontWeight: 400,
                                                      }}
                                                      icon={<UserAddOutlined />}
                                                >
                                                      {isAddingUser
                                                            ? 'Adding team member'
                                                            : 'Add team member'}
                                                </Button>
                                          </Form.Item>
                                    </Form>
                              </Modal>
                        </div>
                  </AppContainer>
            </>
      );
};

export default ProjectSettings;
